'use client';

import React from 'react';
import { motion } from 'framer-motion';

export default function Companies({ heading, icons }) {
  return (
    <div className="text-center w-full pb-10 md:pb-14 h-auto flex flex-col items-center md:gap-14 justify-center">
      <motion.h1
        className="bg-gradient-to-b from-white text-1xl font-bold md:text-[22px] via-white/80 to-white/0 text-transparent bg-clip-text"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        {heading}
      </motion.h1>

      <div className="relative mt-5 sm:mt-0 w-full md:w-[45vw] overflow-hidden block [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <div className="flex">
          <div className="flex animate-slidehorizontal gap-5 md:gap-10">
            {icons?.map(({ icon_id }) => (
              <div
                className="flex items-center justify-center mx-4"
                key={icon_id?.id}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: icon_id?.svg }}
                  className="herologos"
                ></span>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
}
