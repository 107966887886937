import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/HeroSection.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/OurClient.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/services/ServiceCard.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/successstory/SlideCard.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/successstory/StoryCard.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/techstackcomponents/TechStack.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/HomePage/Testimonials.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/accordion.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/NudgeCard.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"components/HomePage/WhyUs.jsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/public/Border.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/check.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/SplBorder.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/x.png")